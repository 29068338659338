@each $size, $value in $sizes {
    .p-#{$size} {
        padding: $value + rem !important;
    }
    .ph-#{$size} {
        padding-left: $value + rem !important;
        padding-right: $value + rem !important;
        @media only screen and (max-width: 900px) {
            padding-left: 10px!important;
            padding-right: 10px!important;
        }
    }
    .pv-#{$size} {
        padding-top: $value + rem !important;
        padding-bottom: $value + rem !important;
    }
    .pt-#{$size} {
        padding-top: $value + rem !important;
    }
    .pb-#{$size} {
        padding-bottom: $value + rem !important;
    }
    .pl-#{$size} {
        padding-left: $value + rem !important;
    }
    .pr-#{$size} {
        padding-right: $value + rem !important;
    }
}

.p-none-media {
    @media only screen and (max-width: 900px) {
        padding: 0!important;
    }
}
