@each $size, $value in $sizes {
	.fs-#{$size} {
		font-size: $value + px;
	}
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-justify {
	text-align: justify;
}

.fw-lighter {
	font-weight: lighter !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-400 {
	font-weight: 400 !important;
}

.fw-300 {
	font-weight: 300 !important;
}

.fw-200 {
	font-weight: 200 !important;
}
