ngx-dropzone {
    height: auto!important;
    border-color: $secondary!important;
    padding-top: 1rem;
    padding-bottom: 1rem;

    ngx-dropzone-label {
        i {
            color: $primary;
            font-size: 8rem;
        }
    }

    // ngx-dropzone-image-preview {
    //     width: 100%!important;
    //     height: 10%!important;
    // }
}
