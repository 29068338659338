.icon-border {
    border-radius: 15px;
    border: 1px solid $light;
    padding: 3px 14px;
    cursor: pointer;
    font-size: 15px;
}

.icon-border:hover {
    background-color: rgb(0 0 0 / 5%);
}
