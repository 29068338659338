@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.3) !important;
    }
    .bg-outline-#{$color} {
        border: 1px solid $value !important;
        color: $value;
    }
}
