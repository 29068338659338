.skeleton {
	background-color: rgb(0 0 0 / 10%);
	border-radius: $border-radius;
	animation-name: color;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	animation-timing-function: ease;
}

@keyframes color {
	to {
		background-color: rgb(0 0 0 / 5%);
	}
}
