@font-face {
	font-family: zeyo;
	src: url('../src/assets/fonts/zeyo.ttf') format('opentype');
}

* {
	font-family: zeyo, arial, sans-serif;
}

html {
	background-color: #f6f6f6;
}

// TODO: ELIMINAR
.event-name {
	background: rgb(1 52 255);
	background: linear-gradient(90deg, rgb(1 52 255 / 100%) 29%, rgb(0 192 255 / 100%) 100%);
	background-color: red;
	padding: 1rem 4rem;
	min-width: 15rem;
	border-radius: 10px;
	text-align: center;
	font-size: 30px;
	@media only screen and (max-width: 900px) {
		font-size: 16px;
		min-width: auto;
	}
}

.imgIcon {
	width: 120px;
	@media only screen and (max-width: 900px) {
		width: 60px;
	}
}

.img-field {
	width: 90%;
	height: 17rem;
	border-radius: 5px;
	object-fit: cover;
	@media only screen and (max-width: 900px) {
		height: 7rem;
	}
}

.subtitle {
	font-size: 24px;
	@media only screen and (max-width: 900px) {
		font-size: 16px;
	}
}

.text {
	font-size: 24px;
	color: white;
	text-decoration: none;
	@media only screen and (max-width: 900px) {
		font-size: 16px;
	}
}

.imgFile {
	width: 100%;
}

.docFile {
	width: 100%;
	height: 30rem;
}

.vertical-align-left-responsive {
	@media only screen and (max-width: 900px) {
		justify-content: left !important;
	}
}

.tooltip-container {
	text-align: center;
	color: #0053ff;
	position: fixed;
	padding: 1rem 2rem;
	font-size: 14px;
	max-width: 25rem;
	line-height: initial;
	width: max-content;
	background: white;
	box-sizing: border-box;
	opacity: 0;
	z-index: 1000000 !important;
	transform: translate(-50%, -30%);
	animation: tooltip-slide 0.18s ease-out 0.1s;
	animation-fill-mode: forwards;
	pointer-events: none;
	border: 1px solid rgb(0 0 0 / 10%);
	border-radius: 5px;
	margin-left: 5rem;

	&::before {
		content: '';
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid white;
		position: absolute;
		left: calc(50% - 5px);
		z-index: 1000000 !important;
		top: -5px;
	}
}

.tooltip-container-right {
	z-index: 1000;
	text-align: center;
	color: #0053ff;
	position: fixed;
	padding: 1rem 2rem;
	font-size: 14px;
	max-width: 25rem;
	line-height: initial;
	width: auto;
	background: white;
	box-sizing: border-box;
	opacity: 0;
	transform: translate(-50%, -30%);
	animation: tooltip-slide 0.18s ease-out 0.1s;
	animation-fill-mode: forwards;
	pointer-events: none;
	border: 1px solid rgb(0 0 0 / 10%);
	border-radius: 5px;

	&::before {
		content: '';
		z-index: 1000;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid white;
		position: absolute;
		left: calc(50% - 5px);
		top: -5px;
	}
}

@keyframes tooltip-slide {
	0% {
		opacity: 0;
		transform: translate(-50%, -30%);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, 0);
	}
}
