.col-responsive {
    @media only screen and (max-width: 900px) {
        display: none;
    }
}

.p-responsive {
    @media only screen and (max-width: 900px) {
        padding: 0;
    }
}
