@import '../scss/variables';

@import './node_modules/bootstrap/scss/bootstrap';
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';

@import './customs/fonts';
@import './customs/progress';
@import './customs/icons';
@import './customs/width';
@import './customs/paddings';
@import './customs/backgrounds';
@import './customs/margins';
@import './customs/buttons';
@import './customs/tables';
@import './customs/dropzone';
@import './customs/borders';
@import './customs/skeleton';

.logo {
	width: 10rem;
}

.fullscreen {
	width: 100%;
	height: 100vh;
}

.page-fullscreen {
	width: 100%;
	height: 80vh;
}

.vertical-align {
	display: flex;
	justify-content: center;
	align-items: center;
}

.vertical-align-left {
	display: flex;
	justify-content: left;
	align-items: center;
}

.vertical-align-right {
	display: flex;
	justify-content: right;
	align-items: center;
}

.position-relative {
	position: relative;
}

.display-none {
	display: none;
}

.title-line-height {
	line-height: 3.5rem;
}

.form-input {
	margin-bottom: 1.3rem;
}

.label {
	margin-bottom: 0.5rem;
}

.label,
.form-control {
	font-size: 15px;
}

.form-control,
.btn,
.form-select,
.border-radius {
	border-radius: 10px;
	border-color: $light;
}

.border-radius-top {
	border-top-right-radius: 10px !important;
	border-top-left-radius: 10px !important;
	border-color: $light;
}

.border-radius-bottom {
	border-bottom-right-radius: 10px !important;
	border-bottom-left-radius: 10px !important;
	border-color: $light;
}

.disclaimer {
	font-size: 12px;
}

.border-radius-right {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}

.border-radius-left {
	border-top-left-radius: 10px !important;
	border-bottom-left-radius: 10px !important;
}

.border-radius-right-none {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.border-radius-left-none {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.disabled {
	background-color: #e9ecef !important;
}

.border-left-0 {
	border-left: none;
	border-radius: 0;
}

.border-right-0 {
	border-right: none;
	border-radius: 0;
}

.border-radius {
	border-radius: 10px !important;
}

.height-content {
	height: 70vh;
}

.height-content-filters {
	height: 55vh;
}

em {
	color: $light;
}

.input-group-text {
	border-color: $light;
}

.limit-email {
	text-overflow: ellipsis;
	width: 20rem;
	white-space: nowrap;
	overflow: hidden;
	@media only screen and (max-width: 900px) {
		width: 16rem;
	}
}

.justify-content {
	justify-content: space-between;
}

.float-right {
	float: right;
}

hr {
	background-color: $secondary !important;
	height: 1px !important;
	z-index: -10 !important;
}

.hr {
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
	height: 1px;
	background-color: $light;
}

.display-flex {
	display: flex;
}

.space-between {
	justify-content: space-between;
}

.box-shadow {
	box-shadow: 0 5px 10px 0 rgb(0 0 0 / 40%);
}

.input {
	height: 2.15rem;
	width: 100%;
	padding: 5px 10px;
	border-radius: 10px;
	background-color: white;
	border: 1px solid $light;
}

.search-input {
	background: url('../icons/search.svg') no-repeat scroll 7px 7px;
	padding-left: 2rem;
	padding-right: 2rem;
}

.infinite_scroll {
	max-height: 42rem;
	width: 100%;
	overflow-y: scroll;
}

.box {
	box-shadow: 0 2px 10px 2px rgb(0 0 0 / 10%);
	border-radius: 1.3rem;
}

.text-map {
	color: #0af;
}

.cursor {
	cursor: pointer;
}

.icon-social-register {
	width: 1.3rem;
}
