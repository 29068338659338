@each $theme, $value in $theme-colors {
	.btn-outline-#{$theme} {
		border-color: $value !important;
	}
}

.spinner-loading-button {
	width: 17px;
	height: 17px;
}

.spinner-loading-button-timeline {
	width: 4.3rem;
	height: 4.3rem;
	font-size: 2rem;
}

.btn-white {
	background-color: white;
}

.btn-social {
	width: 100%;
	padding: 0.5rem 3rem;
	background: white;
	border-radius: 2rem !important;
}

.btn-social:hover {
	background-color: white !important;
}
