@each $size,
$value in $sizes {
    .m-#{$size} {
        margin: $value + rem!important;
    }
    .mh-#{$size} {
        margin-left: $value + rem!important;
        margin-right: $value + rem!important;
    }
    .mv-#{$size} {
        margin-top: $value + rem!important;
        margin-bottom: $value + rem!important;
    }
    .mt-#{$size} {
        margin-top: $value + rem!important;
    }
    .mb-#{$size} {
        margin-bottom: $value + rem!important;
    }
    .ml-#{$size} {
        margin-left: $value + rem!important;
    }
    .mr-#{$size} {
        margin-right: $value + rem!important;
    }
}
